<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In the synthesis of phosphorus pentoxide, elemental phosphorus,
        <chemical-latex content="P4" />, is burned in an oxygen atmosphere according to the
        following balanced equation:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="P4 + 5O2 -> P4O10" />
      </p>

      <p class="mb-1">
        a) What is the limiting reagent when
        <number-value :value="molP4" unit="\text{mol of P}_4" />
        are combined with
        <number-value :value="molO2" unit="\text{mol of O}_2\text{?}" />
      </p>

      <v-radio-group v-model="inputs.limReagent" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block pl-10" :value="option.value" />
          <chemical-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        b) If
        <number-value :value="massP4O10" unit="\text{g of P}_4\text{O}_{10}" />
        are formed from the quantities given in part a), what is the percent yield of the reaction?
      </p>

      <calculation-input
        v-model="inputs.pctYield"
        prepend-text="$\text{Yield:}$"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question238',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        limReagent: null,
        pctYield: null,
      },
      options: [
        {expression: 'P4', value: 'p4'},
        {expression: 'O2', value: 'o2'},
      ],
    };
  },
  computed: {
    molP4() {
      return this.taskState.getValueBySymbol('molP4').content;
    },
    molO2() {
      return this.taskState.getValueBySymbol('molO2').content;
    },
    massP4O10() {
      return this.taskState.getValueBySymbol('massP4O10').content;
    },
  },
};
</script>
